import React, { useEffect, useState } from 'react'
import {
  Box,
  Link,
  Typography,
} from "@material-ui/core";
import { img1475048885HTpe556x7cBoLvN2ZuRRme, imgFacebook, imgInst, imgTwitter, imgYouTube } from './assets';
import { getStorageData } from '../../framework/src/Utilities';


interface StoredDataType {
  contactData: {
    data: [
      {
        id: string;
        type: string;
        attributes: {
          name: string;
          email: string;
          phone_number: string;
          description: string;
          created_at: string;
          user: string;
        };
      }
    ];
  };
  currentPlanStatus: string;
}

export function AppFooter({ navigation }: { navigation: any }) {
  const goToTermsAndCondition = () => navigation.navigate('TermsConditions');
  const isCustomer = localStorage.getItem('userRole') === 'Customer';

  const [storedData, setStoredData] = useState<StoredDataType | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStorageData('contactInfo');
      const parsedData = JSON.parse(data);
      setStoredData(parsedData);

      const userRoleData = await getStorageData('userRole');
      setUserRole(userRoleData);
    };
    fetchData();
  }, []);

  const handleSubscriptionNavigation = () => {

    if (userRole === 'Customer') {
      navigation.navigate('SubscriptionbillingPlans');
    } else {
      navigation.navigate(storedData?.currentPlanStatus === 'Active' ? 'SubscriptionbillingPlans' : 'Subscriptionbilling');
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: '#398378ff',
          padding: '40px 141px'
        }}
        data-view-name={{ viewName: 'group_footer' }}
      >
        <Box>
          <img
            style={{ width: 147, height: 60 }}
            src={img1475048885HTpe556x7cBoLvN2ZuRRme}
          />
        </Box>
        <hr color='#94A3B8' style={{ margin: '32px 0' }} />
        {/* footer bar */}
        <Box
          display='flex'
          justifyContent='flex-start'
        >
          {isCustomer &&
            <>
              <Box minWidth='25%'>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontWeight: 700,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    paddingBottom: '4px'
                  }}
                >{`Explore`}</Typography>
                <Link
                  style={{
                    padding: '4px 0',
                    display: 'block',
                    fontFamily: 'Ubuntu',
                    fontWeight: 400,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigation.navigate('LandingPage')}
                >{`Home`}</Link>
                <Link
                  style={{
                    padding: '4px 0',
                    display: 'block',
                    fontFamily: 'Ubuntu',
                    fontWeight: 400,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleSubscriptionNavigation()}
                >{`Subscriptions`}</Link>
              </Box>
              <Box minWidth='25%'>
                <Typography
                  style={{
                    fontFamily: 'Ubuntu',
                    fontWeight: 700,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    paddingBottom: '4px'
                  }}
                >{`Location`}</Typography>
                <Link
                  style={{
                    padding: '4px 0',
                    display: 'block',
                    fontFamily: 'Ubuntu',
                    fontWeight: 400,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    cursor: 'pointer'
                  }}
                >{`Saudi Arabia`}</Link>
                <Link
                  style={{
                    padding: '4px 0',
                    display: 'block',
                    fontFamily: 'Ubuntu',
                    fontWeight: 400,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    cursor: 'pointer'
                  }}
                >{`Oman`}</Link>
                <Link
                  style={{
                    padding: '4px 0',
                    display: 'block',
                    fontFamily: 'Ubuntu',
                    fontWeight: 400,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    cursor: 'pointer'
                  }}
                >{`Kuwait`}</Link>
                <Link
                  style={{
                    padding: '4px 0',
                    display: 'block',
                    fontFamily: 'Ubuntu',
                    fontWeight: 400,
                    fontSize: 16,
                    letterSpacing: 0,
                    color: '#ffffffff',
                    cursor: 'pointer'
                  }}
                >{`Bahrain`}</Link>
              </Box>
            </>}
          {storedData && storedData.contactData && storedData.contactData.data && storedData.contactData.data.length > 0 && (
            <Box minWidth='25%'>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontWeight: 700,
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  paddingBottom: '4px'
                }}
              >
                {`Contact Us`}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  paddingBottom: '4px'
                }}
              >
                {`Address: ${storedData?.contactData.data[0].attributes.name}`}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  paddingBottom: '4px'
                }}
              >
                {`Mail: ${storedData?.contactData.data[0].attributes.email}`}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  paddingBottom: '4px'
                }}
              >
                {`Phone: ${storedData?.contactData.data[0].attributes.phone_number}`}
              </Typography>
              <Link
                style={{
                  padding: '4px 0',
                  display: 'block',
                  fontFamily: 'Ubuntu',
                  fontWeight: 400,
                  fontSize: 16,
                  letterSpacing: 0,
                  color: '#ffffffff',
                  cursor: 'pointer'
                }}
                onClick={() => navigation.navigate('ContactUsPage')}
              >
                {`Get Support`}
              </Link>
            </Box>
          )}

          <Box minWidth='25%' marginBottom='74px'>
            <a
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                letterSpacing: 0,
                color: '#ffffffff',
                marginBottom: '20px',
                cursor: 'pointer',
                display: 'block'
              }}
              onClick={goToTermsAndCondition}
            >{`Terms & Condition`}</a>
            <Box display='flex' gridGap='20px'>
              <Link style={{ cursor: 'pointer' }}>
                <img src={imgFacebook} alt="img-facebook" />
              </Link>
              <Link style={{ cursor: 'pointer' }}>
                <img src={imgTwitter} alt="img-facebook" />
              </Link>
              <Link style={{ cursor: 'pointer' }}>
                <img src={imgInst} alt="img-facebook" />
              </Link>
              <Link style={{ cursor: 'pointer' }}>
                <img src={imgYouTube} alt="img-facebook" />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box height={62} style={{ backgroundColor: '#000000' }} display='flex' alignItems='center' justifyContent='center'>
        <Typography
          style={{
            fontFamily: 'Ubuntu',
            fontWeight: 400,
            fontSize: 14,
            letterSpacing: 0,
            color: '#ffffffff',
            height: '14px'
          }}
        >{`© 2024 All rights reserved`}</Typography>
      </Box>
    </>
  )
}
