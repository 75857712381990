import React from "react";
import {
    Container,
    Box,
    TextField,
    Button,
    InputLabel,
    Snackbar,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import ContactusController, { Props } from "./ContactusController";
import { AppFooter } from '../../../components/src/AppFooter.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { ErrorComponent } from "./components/ErrorComponent";
import { getTranslationConfig } from '../../../components/src/helpers';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';



const Body = styled('body')({
    backgroundColor: '#D9EEEB',
    height: "180vh"
});

export default class ContactUsPage extends ContactusController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): Promise<void> {
        return new Promise((resolve) => {
            window.scrollTo(0, 0);
            resolve();
        });
    }

    render() {
        const { t, dir, isArabic } = getTranslationConfig();

        return (
            <Body>
                <AppHeader forceUpdate={this.forceUpdate.bind(this)} />
                <Container
                    maxWidth="md"
                    style={{
                        backgroundColor: "#FFF",
                        minHeight: "100vh",
                        marginBottom: "40px",

                    }}
                    dir={dir}
                >
                    {/* Customizable Area Start */}
                    <Box>
                        <p
                            date-testID="contactUs"
                            style={{
                                fontFamily: 'Ubuntu',
                                fontWeight: 700,
                                fontSize: '48px',
                                lineHeight: '56px',
                                letterSpacing: '-1.5%',
                                padding: "20px"
                            }}>
                            {t['contact-us-page-main-title']}
                        </p>
                        <p style={{
                            fontFamily: 'Ubuntu',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                            paddingLeft: "20px",
                            marginTop: "-40px"
                        }}>
                            {t['contact-us-page-main-sub-title']}
                        </p>
                    </Box>
                    <Box display="flex" flexDirection="column" padding={"20px"} >
                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                {t['contact-us-page-label-name']}
                            </CustomInputLabel>
                            <TextField
                                placeholder={t['contact-us-page-placholder-name']}
                                variant="outlined"
                                fullWidth
                                name="name"
                                value={this.state.name}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.getErrorMessage('name')}
                                visible={this.state.errors.name.visible}
                                isArabic={isArabic}
                            />
                        </Box>
                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                {t['contact-us-page-label-email']}
                            </CustomInputLabel>
                            <TextField
                                placeholder={t['contact-us-page-placholder-email']}
                                variant="outlined"
                                fullWidth
                                style={{
                                    borderRadius: "8px"
                                }}
                                name="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.getErrorMessage('email')}
                                visible={this.state.errors.email.visible}
                                isArabic={isArabic}
                            />
                        </Box>

                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                {t['contact-us-page-label-number']}
                            </CustomInputLabel>
                            <TextField
                                placeholder={t['contact-us-page-placholder-number']}
                                variant="outlined"
                                fullWidth
                                style={{
                                    borderRadius: "8px"
                                }}
                                name="contactNumber"
                                value={this.state.contactNumber}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.getErrorMessage('contactNumber')}
                                visible={this.state.errors.contactNumber.visible}
                                isArabic={isArabic}
                            />
                        </Box>
                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                {t['contact-us-page-label-inquiry-title']}
                            </CustomInputLabel>
                            <TextField
                                placeholder={t['contact-us-page-placholder-inquiry-title']}
                                variant="outlined"
                                fullWidth
                                style={{
                                    borderRadius: "8px"
                                }}
                                name="inquiryTitle"
                                value={this.state.inquiryTitle}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.getErrorMessage('inquiryTitle')}
                                visible={this.state.errors.inquiryTitle.visible}
                                isArabic={isArabic}
                            />
                        </Box>
                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                {t['contact-us-page-label-inquiry-description']}
                            </CustomInputLabel>
                            <TextField
                                placeholder={t['contact-us-page-placholder-inquiry-description']}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                style={{
                                    borderRadius: "8px"
                                }}
                                name="inquiryDescription"
                                value={this.state.inquiryDescription}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.getErrorMessage('inquiryDescription')}
                                visible={this.state.errors.inquiryDescription.visible}
                                isArabic={isArabic}
                            />
                        </Box>
                        <Button
                            data-testId="submitInquiry"
                            variant="contained"
                            color="primary"
                            style={{
                                alignSelf: "flex-start", marginTop: "20px",
                                backgroundColor: "#2D6C67",
                                color: "#FFF",
                                textTransform: "none"
                            }}
                            onClick={(event) => this.handleSubmit(event)}
                        >
                            {t['contact-us-page-submit-button']}
                        </Button>
                    </Box>
                    {/* Customizable End Start */}
                </Container>
                <AppFooter navigation={this.props.navigation} />
                <Snackbar
                    dir={dir}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showSnack}
                    style={{
                        backgroundColor: '#FFFFFF',
                        color: '#0F172A',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        boxShadow: '0px 6px 15px -3px #00000026',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon
                            style={{
                                width: '20px',
                                height: '20px',
                                color: '#34D399',
                                marginRight: '8px',
                            }}
                        />
                        <Typography>{this.state.snackMessage}</Typography>
                    </div>
                </Snackbar>
            </Body>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const CustomInputLabel = styled(InputLabel)({
    fontWeight: 700,
    color: '#334155',
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    marginBottom: '5px',
});
// Customizable Area End